//react
import React, { useState } from 'react';
import PaginationUI from '../PaginationUI/PaginationUI';

const PaginationFeature = ({
    totalPage,
    setPage
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setPage && setPage(value);
    };

    return (
        <PaginationUI
            page={currentPage}
            totalPage={totalPage}
            handleChange={handlePageChange}
        />
    );
};

export default PaginationFeature;


