import React from "react";
import moment from "moment";
import InvoicePdf from "./InvoicePdf";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Search from "../../../component/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DynamicTable from "../../../component/DynamicTable";
import WarningModal from "../../../component/WarningModal";
import PaginationFeature from "../../../component/PaginationFeature/PaginationFeature";

export const ManageInvoiceUI = ({
  invoices,
  updateState,
  handleDeleteInvoice,
  handleSearchInvoice,
  isLoading,
  setParams,
  state,
  setPage,
  params
}) => {
  const navigate = useNavigate()

  const columns = [
    "SR.NO.",
    "Number",
    "Customer Name",
    "Invoice Date",
    // "Due Date",
    "Total Amount",
    "Actions",
  ];

  const transformedData = invoices.results?.map((item, index) => ({
    SRNO: (invoices.page - 1) * 20 + (index + 1),
    Number: item.invoiceNo || "N/A",
    Contact: item.shipTo.customerName || "N/A",
    InvoiceDate:
      (item.invoiceDate && moment(item.invoiceDate).format("DD-MM-YYYY")) ||
      "N/A",
    // DueDate: (item.dueDate && moment(item.dueDate).format("DD-MM-YYYY")) || "N/A",
    TotalAmount: `₹${item.total}` || "N/A",
    Actions: {
      value: (
        <div className=" flex justify-around items-center gap-4">
          <span onClick={() => navigate("/inventory/view-invoice", { state: { data: item } })} className=" cursor-pointer text-xl">
            <GrView />
          </span>
          <span className=" cursor-pointer text-xl">
            <PDFDownloadLink fileName='invoice.pdf' document={<InvoicePdf data={item} />}>
              <FaCloudDownloadAlt />
            </PDFDownloadLink>
          </span>
          <span onClick={() => updateState({ id: item._id, isModalOpen: true })} className=" cursor-pointer"><DeleteIcon /></span>
        </div>
      ),
      bold: false,
    },
  }))

  return (
    <div>
      {/* Header */}
      <div className="flex md:flex-row flex-col md:justify-between justify-start  gap-3 mb-6 md:px-2 px-4 md:py-0 py-1">
        <h1 className="md:text-[28px] text-[20px] font-bold text-gray-800 ">
          Manage Invoice
        </h1>
        <div>
          <Search
            handleSeachButton={() => handleSearchInvoice(1)}
            onChange={(e) => updateState({ query: e.target.value })}
            value={state.query}
            placeholder="Customer Name or Inv No."
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-md md:p-6 p-2 min-h-screen">
        {/* Dynamic Table */}
        <DynamicTable
          data={transformedData}
          columns={columns}
          defaultRowSelected={[]}
          checkbox={false}
        />
        <PaginationFeature
          totalPage={invoices.totalPages}
          setPage={setPage}
          page={invoices.page}
        />
      </div>

      <WarningModal
        isOpen={state.isModalOpen}
        onClose={() => updateState({ isModalOpen: false })}
        modalTitle="Are you sure you want to delete this item?"
        onConfirm={handleDeleteInvoice}
        onCancel={() => updateState({ isModalOpen: false })}
        confirmText={isLoading ? "Deleting..." : "Delete"}
        cancelText="Cancel"
        isLoading={isLoading}
      />
    </div>
  );
};

