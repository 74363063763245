import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Divider } from "@mui/material";
import { FaFileInvoice } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const MobileSideBarUI = ({ onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const sideBarData = [
        {
            id: "manageUsers",
            icon: <CreditCardIcon />,
            label: "Invoice",
            url: "/inventory/create-invoice",
        },
        {
            id: "allApplicants",
            icon: <PeopleAltIcon />,
            label: "User Management",
            url: "/inventory/users",
        },
        {
            id: "manageInvoice",
            icon: <FaFileInvoice />,
            label: "Manage Invoice",
            url: "/inventory/manage-invoice",
        },
        // {
        //     id: "manageProduct",
        //     icon: <FaFileInvoice />,
        //     label: "Manage Product",
        //     url: "/inventory/manage-product",
        // },
    ];

    const handleNavigation = (url) => {
        console.log(`Navigating to: ${url}`);
        navigate(url);
        if (onClose) onClose();
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div className="text-[#f64759] text-[25px] font-bold pl-5 pb-1">AMBER SILK MILS</div>
            <Divider sx={{ backgroundColor: "#ffffff" }} />

            <List>
                {sideBarData.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigation(item.url)}
                            sx={{
                                backgroundColor:
                                    location.pathname === item.url
                                        ? "#f64759"
                                        : "transparent",
                             
                                borderRadius: "4px",
                                margin: "4px 8px",
                            }}
                        >
                            <item.icon.type
                                className={`${
                                    location.pathname === item.url
                                        ? "text-white"
                                        : "text-black"
                                }`}
                            />
                            <ListItemText
                                primary={item.label}
                                sx={{
                                    color:
                                        location.pathname === item.url
                                            ? "#ffffff"
                                            : "black",
                                    paddingLeft: "8px",
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default MobileSideBarUI;
